
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100wh;
  
  }

  .app-container {
    max-width: 560px;
    margin: 0 auto;
    border: 1px solid black; 
  }
  
  @media (max-width: 480px) {
    .app-container {
      width: 100%;
    }
  }

  .container-box {
    margin-top: 80%;
  }
    
  .tabs {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  
  .tab-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .tab-content {
    margin-bottom: 20px;
  }
  
  .footer {
    background-color: lightgray;
    padding: 10px;
  }

  .navbar-brand{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 60px;
    left: 0;
    background-color: #c78c06;
}
  
  .tab-navigation {
    background-color: lightgray;
    padding: 10px;
  }
  
  .tab-content {
    padding: 20px;
  }
  
  .rounded-icon {
    position: relative;
    padding: 0;
  }
  
  .fab {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #a18e00;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  }

  .logo {
    height: 50px; /* Adjust as needed */
    width: auto;
  }
  